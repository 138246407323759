import Swiper from 'swiper';
import {Navigation, Pagination, EffectCreative, Autoplay } from "swiper/modules";
Swiper.use([Pagination, Navigation, EffectCreative, Autoplay]);

Swiper.use([Navigation]);
const industriesSlider  = new Swiper('.industries-slider',{
    direction: 'horizontal',
    slidesPerView: 1,
    autoHeight: false,
    loop: true,
    effect: 'creative',
    speed: 800,
    creativeEffect: {
        limitProgress: 3,
        prev: {
            translate: [0, 0, 0],
            opacity: 0
        },
        next: {
            translate: ["108%",0, 0],
        },
    },
    navigation:{
        nextEl: ".industries-swiper-button-next",
        prevEl: ".industries-swiper-button-prev",
    },
    breakpoints: {
        300: {
            slidesPerView: 1,
        },
        481: {
            slidesPerView: 2.8,
        },
    }
})
