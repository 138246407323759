import $ from 'jquery'

const $callout_containers = $('.callout-container')
const $callout_wrappers = $('.callout-wrapper')
const $callout_overlay = $('.callout-overlay')
const $screen_overlay = $('.screen-overlay')

$('.callout_button').on('click', (e)=> {
    const $this = $(e.currentTarget)
    const $data_name = $this.data('name')
    $callout_containers.each(function() {
        const $self = $(this)
        if ($self.data('name') === $data_name) {
            $self.parent().fadeIn()
            if($(window).innerWidth() < 1200) {
                $screen_overlay.fadeIn()
            } else {
                $callout_overlay.fadeIn()
            }
        } else {
            $self.parent().hide()
        }
    });
})

$('.callout-close').on('click', ()=> {
    $callout_wrappers.fadeOut()
    $callout_overlay.fadeOut()
    $screen_overlay.fadeOut()
})