const mobileMenuBreakpoint = 1300;
const $win = $(window);
const $body = $('body');

const $header = $('.site-header-wrap');
const $headerMenu = $('.site-header .site-header__menu');
const $headerNav = $('.site-header .site-header__nav');

function NavigateMobileSubMenu(level, $subMenu) {
    $subMenu = $subMenu || null;
    if($subMenu) {
        $headerMenu.find('.sub-menu[data-depth=' + level + ']').removeClass('active');
        $subMenu.addClass('active');
    }
    $headerMenu.css('transform', 'translate(' + (-100 * level) + '%)');
    $headerNav.attr('data-currentlevel', level);
}

$('.site-header .sub-menu-back').click(function () {
    const level = $(this).attr('data-backlevel');
    NavigateMobileSubMenu(level);
});

$('.site-header .menu-item-has-children').each(function (){
    const $self = $(this);
    const $link = $self.find('> a');
    const $subMenu = $self.find('> .sub-menu');
    const $subMenuBack = $subMenu.find('> .sub-menu__back');

    const linkHref = $link.attr('href');

    if(linkHref !== '#' && linkHref !== '' && linkHref !== undefined) {
        const $menuItemDuplicate = $('<li class="menu-item menu-item--duplicate"></li>');
        $link.clone().appendTo($menuItemDuplicate);
        $subMenuBack.after($menuItemDuplicate);
    }

    $link.click(function (event) {
        console.log('CLICKED');
        if($win.width() <= mobileMenuBreakpoint) {
            console.log('HEY Prevent');
            event.preventDefault();
            NavigateMobileSubMenu($subMenu.attr('data-depth'), $subMenu);
        }
    });
});

$('.mobile-close, .mobile-menu-toggle').click(function () {
    $headerNav.toggleClass('open');
    $(this).toggleClass('is-active');
});

// Add header height as css var.
function setMenuHeightVar() {
    $body.css('--header-height', $header.height() + 'px');
}

// Do this on resize
window.addEventListener('resize', event => {
    setMenuHeightVar();
});
