const $input = $('input, textarea');

$input.on('focus', function(){
	$(this).closest('.gfield').find('label').addClass('away');
});

$input.on('blur', function(){
	if( !$(this).val() ){
		$(this).closest('.gfield').find('label').removeClass('away');
	}
});

$('input[type="file"]').on('focus', function(){
	$(this).closest('.gfield').find('label').addClass('focus');
});
$('input[type="file"]').on('blur', function(){
	$(this).closest('.gfield').find('label').removeClass('focus');
});

$(window).on('resize load', ()=> {
	if($(window).innerWidth() < 1270) {
		$('footer .gform-body .ginput_container_email input').attr('placeholder', 'Enter your email')
	} else {
		$('footer .gform-body .ginput_container_email input').attr('placeholder', 'Enter your email address here to receive news, product updates, and promotions')
	}
})

$('footer .gfield--type-html').on('click', ()=> {
	$('.gform_footer .gform_button').click()
})
