import Swiper from 'swiper';

const categorySlider  = new Swiper('.product-category-slider',{
    direction: 'horizontal',
    slidesPerView: 1,
    autoHeight: false,
    loop: true,
    spaceBetween: 75,
    effect: 'creative',
    speed: 800,
    // autoplay: {
    //     delay: 4000,
    // },
    creativeEffect: {
        limitProgress: 3,
        prev: {
            translate: [0, 0, 0],
            opacity: 0
        },
        next: {
            translate: ["120%",0, 0],
        },
    },
    navigation:{
        nextEl: ".category-swiper-button-next",
        prevEl: ".category-swiper-button-prev",
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: 'true',
    },
    breakpoints: {
        300: {
            slidesPerView: 1,
        },
        480: {
            slidesPerView: 2.8,
        },
    }
})
