import $ from 'jquery'
$('.wp-block-button, .button-rollover, .request-rent, .add-to-cart-wrapper, .button-rollover-dark, #menu-main-menu > li').each(function() {
    const $this = $(this)
    let originalButton = $this.find('> a');
    if(originalButton.length === 0) {
        originalButton = $this.find('> button');
    }
    const clonedButton = originalButton.clone();
    clonedButton.addClass('clonedButton')
    if($this.hasClass('menu-item')) {
        $this.append(clonedButton);
        clonedButton.wrap('<div class="cloned-button-container"></div>')
    } else {
        $this.append(clonedButton);
    }
});