import $ from 'jquery'

const $form = $('.variations_form')
const $price = $('.summary .product_price');
const $stock = $('.summary .stock_wrap');
const $desc = $('.woocommerce-product-details__short-description');
const $sku = $('.product_meta .sku_wrapper');
const $weight = $('.product-weight .weight')
const $dimensions = $('.product-dimensions .dimensions')
const $tensioners = $('.select-tensioner')

const price_html = $price.html();
const stock_html = $stock.html();
const desc_html = $desc.html();
const sku_html = $sku.html();
const weight_html = $weight.html()
const dimensions_html = $dimensions.html()

let last_tensioner;
let run_event_once;

$form.on('found_variation', function (event, variation) {
    $desc.html(variation.variation_description ? variation.variation_description : desc_html )
    $price.html(variation.price_html ? variation.price_html + `<span>${variation.pair_or_each}</span>` : price_html);
    $stock.html(variation.availability_html ? variation.availability_html : stock_html);
    $sku.html(variation.sku ? '<span>Sku:</span>' + '<p class="sku">' + variation.sku + '</p>'  : sku_html);
    $weight.html(variation.weight ? variation.weight : weight_html);
    $dimensions.html(variation.dimensions_html ? variation.dimensions_html : dimensions_html);
    if (last_tensioner !== variation.tensioners) {
        $tensioners.html(variation.tensioners)
        last_tensioner = variation.tensioners
    }
});

$form.on('hide_variation', function () {
    $price.html(price_html);
    $stock.html(stock_html);
    $desc.html(desc_html);
    $sku.html(sku_html);
    $dimensions.html(dimensions_html);
    $weight.html(weight_html);
    $tensioners.html('');
    last_tensioner = null;
});

$form.on('change', '.tensioner_radio [type=radio]', e => {
    const $radio = $(e.currentTarget).closest('.tensioner_radio')
    $radio.find('.quantity-wrap').show().find('input').prop("disabled", false)
    $radio.siblings().find('.quantity-wrap').hide().find('input').prop("disabled", true);
})

$form.on('change', '.variations select', e => {
    $(e.target).removeClass('required').next().removeClass('required')
    setTimeout(() => {
        if (run_event_once) return;
        run_event_once = true;
        $('.woo-variation-items-wrapper').each((i, element) => {
            const $item = $(element).find('.variable-item:not(.disabled)');
            if ($item.length === 1 && !$item.hasClass('selected')) $item.click();
        })
        run_event_once = false;
    })
})

$form.on('click', '.single_add_to_cart_button', e => {
    const $this = $(e.target)
    if ($this.is('.wc-variation-selection-needed')) {
        $form.find('select').each((i, select) => {
            const $select = $(select)
            if (!$select.val()) $select.addClass('required').next().addClass('required');
        })
    }
})

$form.on('click', '.tensioner_radio .embed', e => {
    e.stopPropagation();
    e.preventDefault();
    $('body').append(`
    <div style="position: fixed; width: 100vw; height: 100vh; top: 0; left: 0; background-color: rgb(0 0 0 / 50%); z-index: 100; display: flex; justify-content: center; align-items: center;" onclick="this.remove();">
        <div style="position: absolute; top: 20px; right: 40px; color: white; font-size: 40px; cursor: pointer;">X</div>
        <iframe src="${e.currentTarget.href}" width="600" onload="this.style.height=this.contentWindow.document.body.scrollHeight+'px';">
    </div>`)
})

$form.on('click', '.tensioner_radio', e => {
    const $input = $(e.currentTarget).find('[type=radio]')
    $input.prop('checked', true)
    $input.trigger('change')
})

$('.variable-item').hover(function() {
    const $this = $(this);
    if ($this.hasClass("disabled")) {
        const buttonPosition = $this.offset();
        const tooltipHeight = $('.reset-selections-tooltip').outerHeight();

        const tooltipLeft = buttonPosition.left;
        const tooltipTop = buttonPosition.top + tooltipHeight - 13;

        $('.reset-selections-tooltip').css({
            left: tooltipLeft,
            top: tooltipTop
        }).show();

        $this.mouseleave(function() {
            $('.reset-selections-tooltip').hide();
        });
    }
}, function() {
    $('.reset-selections-tooltip').mouseenter(function() {
        $(this).stop().show();
    }).mouseleave(function() {
        $(this).stop().hide();
    });
});

$('.reset-selections-tooltip span').on('click', function() {
    let $attribute_select = $('.variations_form').find( '.variations select' );
    $attribute_select.val( '' ).trigger( 'change' );
    $('.variations_form').trigger( 'reset_data' )
})

$(".variable-item").click(function(event) {
    if($(this).hasClass('disabled')) {
        event.stopPropagation();
    }
});
