import Swiper from 'swiper';

const testimonialSlider  = new Swiper('.testimonial-slider',{
    direction: 'horizontal',
    slidesPerView: 1,
    autoHeight: false,
    loop: true,
    effect: 'fade',
    speed: 1000,
    fadeEffect: {
        crossFade: true
    },
    autoplay: {
        delay: 4000,
    },
    navigation:{
        nextEl: ".testimonial-swiper-button-next",
        prevEl: ".testimonial-swiper-button-prev",
    }
})
