$('.quantity-wrap').each(function (){
    const $self = $(this)
    const $steppers = $self.find('.quantity-stepper');
    const $input = $self.find('input.qty');
    console.log($input);
    $steppers.click(function () {
        const $thisStepper = $(this);
        const increment = Number($thisStepper.data('increment'));
        console.log(increment);

        // We have to get min and max on click bc these values change on variation select
        const min = Number($input.attr('min'));
        const max = Number($input.attr('max'));
        const step = Number($input.attr('step'));

        const initialValue = Number($input.val());

        console.log(min, max, step);
        if(max) {
            $input.val(Math.min(max, Math.max(min, initialValue + (step * increment))));
        } else {
            $input.val(Math.max(min, initialValue + (step * increment)));
        }
    });
});
